#videoBox{
 height: 100%;         
}
.modal-content{
    border-radius: 16px;
}
/* #leftContainer{
    display: none;
} */
@media screen and (max-width: 690px) {

    /* #chatExpiryPopupView{
        width: 90%;
    }
    #chatExpiryPopupView{
        width: 90%;
    } */
    #modalContainer{
        /* width: 90%; */
    }

    #modalContainer #headerLabel{
        font-size: 3vh
    }

    #modalContainer #smallHeaderLabel{
        font-weight: 600;
        color: rgb(42, 42, 42);
        width: 100%;
        margin: 14px 0px 15px;
        font-size: 13px;    
    }

    #modalContainer #desc{
        margin-left: 2%;
        color: rgb(66, 158, 250);
        font-size: 13px;
        line-height: 1.5;
        font-weight: 500;
    }

    #modalContainer #checkedButtonContainer{
        /* margin: 12px 22px 12px 22px; */
    }

    #modalContainer #modalbtn{
        font-weight: 600;
        border: none;
        color: white;
        cursor: pointer;
        margin: 5px 0;
        padding: 6px 15px 6px 15px;
        border-radius: 20px;
    }

    #modalContainer #checkbox{
        width: 2.5vh;
        height: 2.5vh;
    }

  }

@media screen and (max-width: 767px) {
    #videoBox {
        display: none;   
    }
}
@media screen and (min-width: 768px) {
    #videoBox {
        display: flex;
        align-items: flex-end;
    }
    #videoBox video{
        height: 80%;   
    }
}
@media screen and (max-width: 849px) {
    #chatbotMsgLabel{
        font-size : 14px;
        padding: 2vh;
        max-width: 100%;
        line-height: 1.2;
        border-top-right-radius: 5vh;
        border-bottom-left-radius: 4vh;
        border-bottom-right-radius: 5vh;
        margin: 0.5vh;  
    }
    #optionBtns{
        font-size: 12px;
        padding: 1.1vw 2vw;
    }
}
@media screen and (min-width: 992px) {
    #videoBox video{
        height: 100%;   
    }
}
      